import './index.css'

import React from 'react'
import ReactDOM from 'react-dom/client'

import RouterRoot from './router.tsx'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <RouterRoot />
  </React.StrictMode>,
)
